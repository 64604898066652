import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AutenticacaoService } from 'src/app/services/autenticacao.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private autenticacaoService: AutenticacaoService) {}

    canActivate() {
        if (!this.autenticacaoService.isActive()) {
            this.router.navigate(['/login']);
        }

        return true;
    }
}
