export class LoginRequest {
    constructor(
        Login: string,
        Senha: string
    ) {
        this.Login = Login;
        this.Senha = Senha;
    }

    Login: string;
    Senha: string;
}
