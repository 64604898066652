import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoginRequest } from '../models/login';
import { Usuario, UsuarioLoginResponse } from '../models/usuario';

@Injectable({
    providedIn: 'root'
})
export class AutenticacaoService {

    private baseUrl: string;

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.urlApiDashboard + '/seguranca';
    }

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    login(username: string, senha: string): Observable<UsuarioLoginResponse> {

        const loginRequest = new LoginRequest(username, senha);
        return this.httpClient.post<Usuario>(`${this.baseUrl}/login`,
                                              JSON.stringify(loginRequest),
                                              this.httpOptions
               ).pipe(
                 tap( (res: UsuarioLoginResponse ) => {                  

                    localStorage.setItem('usuario', JSON.stringify(res));                   
                 }),
                 retry(1),
                 catchError(this.handleError)
              );
    }

    isActive = (): boolean =>
       localStorage.getItem('usuario') !== null
            && localStorage.getItem('usuario').length > 0

    logout() {
      localStorage.removeItem('usuario');
    }

    getUsuarioInfo = () => {
      return (JSON.parse(localStorage.getItem('usuario')) as UsuarioLoginResponse).Usuario;
    }

    // Manipulação de erros
    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Erro ocorreu no lado do client
          errorMessage = error.error.message;
        } else {
          // Erro ocorreu no lado do servidor
          errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
        }
       
        return throwError(errorMessage);
      }
}
