import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsuarioLoginResponse } from '../models/usuario';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private router: Router) { } 

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    if(!req.url.includes('.json'))
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.obterTokenUsuario()}`
        }
      });
    else{
      req = req.clone({
        setHeaders: {
          'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
    }

    return next.handle(req).pipe( tap((x) => {
       // this.ngxLoader.stop();
    },
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        
        switch (err.status) {
          case 500:
          case 501:
          case 502:
            Swal.fire({
              text: err.error.message,
              titleText: 'Atenção',
              icon: 'info',
              confirmButtonColor: '#4a9f42'
            });
            return;
            case 400:
              Swal.fire({
                text: err.error.message,
                titleText: 'Atenção',
                icon: 'info',
                confirmButtonColor: '#4a9f42'
              });
               return;
            case 404:
              Swal.fire({
                text: err.error.message,
                titleText: 'Atenção',
                icon: 'info',
                confirmButtonColor: '#4a9f42'
              });
              return;
          case 401:
          case 402:
          case 403:
              localStorage.removeItem('usuario');
              this.router.navigate(['/login']);

              Swal.fire({
                text: err.error.message,
                titleText: 'Atenção',
                icon: 'info',
                confirmButtonColor: '#4a9f42'
              });
            return;
        }
      }
    }));

    // this.requests.push(req);
  }

  // protected ObterAuthHeaderJson() {
  //   return {
  //       headers: new HttpHeaders({
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${this.obterTokenUsuario()}`
  //       })
  //   };
  // }

  protected obterTokenUsuario(): string {
    const usuarioData = localStorage.getItem('usuario');
    return usuarioData !== undefined && usuarioData !== null ?
            (JSON.parse(localStorage.getItem('usuario')) as UsuarioLoginResponse).Token :
            '';
  }

}
